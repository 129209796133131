<template>
  <div>
    <div class="banner" :style="cover">
      <div class="article-info-container">
      </div>
    </div>
    <v-card class="blog-container">
      <div class="kcode">
        <!-- pj -->
        <label for="host-input">项目:</label>
        <input type="text" v-model="pj.project" />
        <label for="host-input">模块:</label>
        <input type="text" v-model="pj.model" /><br />
        <label for="host-input">包路径:</label>
        <input type="text" v-model="pj.rootPath" />
        <label for="host-input">版本:</label>
        <input type="text" v-model="pj.version" /><br />
      </div>
      <div class="tabs">
        <ul class="kcode-ul">
          <li :class="{ active: active === 1 }" data-tab="tab1" @click="active = 1">JDBC</li>
          <li :class="{ active: active === 2 }" data-tab="tab2" @click="active = 2">DDL</li>
        </ul>
        <div class="kcode">
          <div v-show="active === 1" class="tab active">
            <div class="jdbc">
              <label for="host-input">Host:</label>
              <input type="text" v-model="jdbc.host" />
              <label for="port-input">Port:</label>
              <input type="text" v-model="jdbc.port" /><br />
              <label for="user-input">User:</label>
              <input type="text" v-model="jdbc.user" />
              <label for="pass-input">Password:</label>
              <input type="password" v-model="jdbc.pass" /><br />
              <label for="db-input">Database:</label>
              <input type="text" v-model="jdbc.db" />
              <label for="prefix-input">TablePrefix:</label>
              <input type="text" v-model="jdbc.pre_fix" /><br />
              <button @click="submitJdbc">{{ loading ? 'loading...' : 'Gen' }}</button>
            </div>
          </div>
          <div v-show="active === 2" class="tab">
            <textarea v-model="ddl.msg"></textarea><br />
            <button @click="submitDdl">{{ loading ? 'loading...' : 'Gen' }}</button>
          </div>
        </div>
      </div>
    </v-card>
  </div>
</template>

<script>
export default {
  created() {
  },
  components: {

  },
  data: function () {
    return {
      loading: false,
      active: 1,
      pj: {
        model:"bbs",
        project:"demo",
        rootPath:"github.com/demo",
        version:"v1",
      },
      jdbc: {
        host: "localhost",
        port: "3306",
        user: "root",
        pass: "root",
        db: "test",
        pre_fix: "t_"
      },
      ddl: {
        msg: ""
      }
    };
  },
  methods: {
    submitJdbc() {
      this.loading = true;
      Object.assign(this.jdbc, this.pj);
      this.axios
        .post("/apik/jdbc", this.jdbc, {
          responseType: 'blob'
        })
        .then(response => {
          this.loading = false;
          //_1681386322.zip_
          let filename = response.headers['content-disposition'].split('filename=')[1].replace(/"/g, '').replace(/_/g, ''); // 获取文件名
          console.log(filename);
          const fileReader = new FileReader();
          fileReader.onload = () => {
            const downloadLink = document.createElement('a');
            downloadLink.setAttribute('href', `data:application/zip;base64,${btoa(fileReader.result)}`);
            downloadLink.setAttribute('download', filename);
            downloadLink.style.display = 'none';
            document.body.appendChild(downloadLink);
            downloadLink.click();
            document.body.removeChild(downloadLink);
          };
          fileReader.readAsBinaryString(response.data); // 读取文件内容
        })
        .catch(() => {
          this.loading = false;
        })
    },
    submitDdl() {
      this.loading = true;
      Object.assign(this.ddl, this.pj);
      this.axios
        .post("/apik/ddl", this.ddl, {
          responseType: 'blob'
        })
        .then(response => {
          this.loading = false;
          let filename = response.headers['content-disposition'].split('filename=')[1].replace(/"/g, '').replace(/_/g, ''); // 获取文件名
          console.log(filename);
          const fileReader = new FileReader();
          fileReader.onload = () => {
            const downloadLink = document.createElement('a');
            downloadLink.setAttribute('href', `data:application/zip;base64,${btoa(fileReader.result)}`);
            downloadLink.setAttribute('download', filename); // 使用返回的文件名
            downloadLink.style.display = 'none';
            document.body.appendChild(downloadLink);
            downloadLink.click();
            document.body.removeChild(downloadLink);
          };
          fileReader.readAsBinaryString(response.data); // 读取文件内容
        })
        .catch(() => {
          this.loading = false;
        })
    }
  },
  computed: {
    cover() {
      var cover = "";
      this.$store.state.blogInfo.pageList.forEach(item => {
        if (item.pageLabel == "sd") {
          cover = item.pageCover;
        }
      });
      return "background: url(" + cover + ") center center / cover no-repeat";
    }
  },
};
</script>

<style>
.kcode-ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.kcode-ul li {
  display: inline-block;
  padding: 10px 20px;
  background-color: #ccc;
  color: #fff;
  cursor: pointer;
}

.kcode-ul li.active {
  background-color: #333;
}


.jdbc div {
  min-width: 700px;
  margin: auto;
  border: 1px solid #ddd;
  padding: 10px;
  border-radius: 5px;
}

.kcode div {
  position: relative;
  min-height: 240px;
}

.kcode label {
  width: 80px;
  margin: 10px 10px 10px 10px;
  display: inline-block;
  margin-bottom: 5px;
}

.kcode input {
  width: 200px;
  padding: 5px;
  margin: 10px 10px 10px 10px;
  border: 1px solid #ddd;
  outline: #0077cc;
  border-radius: 5px;
}

.kcode textarea {
  width: 100%;
  min-height: 200px;
  outline: none;
  background-color: rgb(198, 248, 183);
}

.kcode button {
  float: inline-end;
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 5px 10px;
  border: none;
  background-color: #0077cc;
  color: #fff;
  cursor: pointer;
}
</style>